document.addEventListener('DOMContentLoaded', function () {
  // Only run this code if we're on the certificate show page
  const certificateShow = document.getElementById('certificate-show');
  if (!certificateShow) return;

  $('#certificable_autocomplete').bind('railsAutocomplete.select', function (_, data) {
    $('#certificable_id').val(data.item.id);
    $('#certificable_type').val(data.item.type);
  });

  const versionSelects = document.querySelectorAll('.certificate-version-select');

  versionSelects.forEach((select) => {
    // Store original value to revert if needed
    const originalValue = select.value;

    select.addEventListener('change', function (event) {
      const form = event.target.closest('form');
      // const _certificateId = form.dataset.certificateId;

      if (confirm('Are you sure you want to change the certificate version?')) {
        const formData = new FormData(form);

        fetch(form.action, {
          body: new URLSearchParams(formData),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          },
          method: 'PATCH',
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              // Success message
              const successMessage = document.createElement('div');
              successMessage.className = 'alert alert-success';
              successMessage.textContent = 'Certificate version updated successfully!';

              // Insert before the form
              form.parentNode.insertBefore(successMessage, form);

              // Update the "Current" text
              const currentText = form.nextElementSibling;
              currentText.textContent = `Current: ${select.value}`;

              // Remove message after 3 seconds
              setTimeout(() => successMessage.remove(), 3000);
            } else {
              // Error handling
              throw new Error(data.error || 'Failed to update version');
            }
          })
          .catch((error) => {
            console.error('Error:', error);

            // Error message
            const errorMessage = document.createElement('div');
            errorMessage.className = 'alert alert-danger';
            errorMessage.textContent = `Error: ${error.message}`;

            form.parentNode.insertBefore(errorMessage, form);

            // Reset selection
            select.value = originalValue;

            // Remove message after 5 seconds
            setTimeout(() => errorMessage.remove(), 5000);
          });
      } else {
        // Reset if user cancels
        select.value = originalValue;
      }
    });
  });
});
