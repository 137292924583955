export const initialState = {
  aiEvaluationState: {
    id: null,
    inProgress: false,
    status: null,
  },
  consentModalIsOpen: false,
  modalIsOpen: false,
  taskTimeEstimationSaved: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'HYDRATE_WITH_DATA_ATTRIBUTES':
      return {
        ...state,
        afa: action.afa,
        attendeeId: action.attendeeId,
        course: action.course,
        errors: {},
        showSurvey: action.showSurvey === 'true',
        showTimeSurvey: action.showTimeSurvey === 'true',
        wsgZone: action.wsgZone,
      };

    case 'LOADING_TASK_MODAL':
      return {
        ...state,
        isLoadingModal: action.isLoading,
      };

    case 'TASK_SUBMISSION_SAVED':
      return {
        ...state,
        ...action.data,
      };

    case 'TOGGLE_TASK_SUBMISSION_MODAL':
      return {
        ...state,
        modalIsOpen: action.modalIsOpen,
      };

    case 'TOGGLE_AI_CONSENT_MODAL':
      return {
        ...state,
        consentModalIsOpen: action.modalIsOpen,
      };

    case 'TASK_TIME_ESTIMATION_SAVED':
      return {
        ...state,
        taskTimeEstimationSaved: true,
      };

    case 'TASK_SUBMISSION_FAILURE':
      return {
        ...state,
        errors: action.data.errors,
      };

    case 'FETCH_RATINGS_SUCCESS':
      return {
        ...state,
        ratings: action.ratings,
      };

    case 'SUBMIT_RATING_SUCCESS': {
      const newRatings = state.ratings.map((rating) =>
        rating.survey_question_id !== action.payload.surveyQuestionId
          ? rating
          : {
              rating: action.payload.exerciseRating,
              survey_question_body: state.ratings.find((rating) => rating.survey_question_id === action.payload.surveyQuestionId)
                .survey_question_body,
              survey_question_id: action.payload.surveyQuestionId,
            }
      );
      return {
        ...state,
        ratings: newRatings,
      };
    }

    case 'SET_FILE_UPLOAD_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          fileUpload: action.errorMessage,
        },
      };

    case 'SET_AI_EVALUATION_STATE':
      return {
        ...state,
        aiEvaluationState: {
          ...state.aiEvaluationState,
          id: action.id || null,
          inProgress: action.inProgress,
          status: action.status,
        },
        errors: {
          ...state.errors,
          aiEvaluation: action.isError || null,
        },
      };

    case 'FETCH_AI_SUBMISSION_HISTORY':
      return {
        ...state,
        aiSubmissionHistory: {
          attempts: action.data.attempts,
          attemptsCount: action.data.attemptsCount,
          hasApproved: action.data.hasApproved,
        },
      };

    default:
      return state;
  }
}
