import { Button } from '@careerfoundry/bubble';
import React from 'react';

interface AiSubmissionButtonProps {
  isAiEvaluationUnderProgress: boolean;
  aiTutorConsent: boolean;
  submitTaskForAiEvaluation: () => void;
  handleAiEvaluationWithoutConsent: () => void;
}

const AiSubmissionButton = ({
  isAiEvaluationUnderProgress,
  aiTutorConsent,
  submitTaskForAiEvaluation,
  handleAiEvaluationWithoutConsent,
}: AiSubmissionButtonProps) => {
  return (
    <Button
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (aiTutorConsent) {
          submitTaskForAiEvaluation();
        } else {
          handleAiEvaluationWithoutConsent();
        }
      }}
      label='Evaluate with AI Evaluate'
      size='standard'
      variant='secondary'
      disabled={isAiEvaluationUnderProgress}
    />
  );
};

export default AiSubmissionButton;
