import { Button } from '@careerfoundry/bubble';
import React from 'react';
import AiSubmissionButton from '../AiSubmissionButton';
import AiConsentModal from '../AiConsentModal';
import { Wrapper } from './styles';

interface TaskSubmissionButtonProps {
  isLoadingModal: boolean;
  isAiEvaluationUnderProgress: boolean;
  submitTask: () => void;
  submitTaskForAiEvaluation: () => void;
  isAiTutorEnabled: boolean;
  aiTutorConsent: boolean;
  consentModalIsOpen: boolean;
  handleAiEvaluationWithoutConsent: () => void;
  toggleConsentModal: (modalIsOpen: boolean) => void;
  aiSubmissionCount: number;
  hasApprovedSubmission: boolean;
}

interface ISubmissionAttempt {
  isAiTutorEnabled: boolean;
  isAiEvaluationUnderProgress: boolean;
  submissionCount: number;
  hasApprovedSubmission: boolean;
}

const canSendToEvaluate = (step: ISubmissionAttempt) => {
  if (!step.isAiTutorEnabled) {
    return false;
  } // should not show if is not AI enabled
  if (step.isAiEvaluationUnderProgress) {
    return false;
  } // should not show if evaluation under pgoress
  if (step.hasApprovedSubmission) {
    return false;
  }

  return true;
};

const canSendToInstructor = (step: ISubmissionAttempt) => {
  if (!step.isAiTutorEnabled) {
    return true;
  } // should show if is not AI enabled
  // if(step.submissionCount == 0) { return false } // should have at least one send to AI Evaluate (disabled: true)
  if (step.isAiEvaluationUnderProgress) {
    return false;
  } // should not show if there is an event in progress
  // if(step.hasApprovedSubmission) { return false } // should not show if there is an AI submission approved (disabled: true )

  return true;
};

const TaskSubmissionButton = ({
  isLoadingModal,
  submitTask,
  submitTaskForAiEvaluation,
  isAiEvaluationUnderProgress,
  isAiTutorEnabled,
  aiTutorConsent,
  consentModalIsOpen,
  handleAiEvaluationWithoutConsent,
  toggleConsentModal,
  aiSubmissionCount,
  hasApprovedSubmission,
}: TaskSubmissionButtonProps) => {
  const submissionAttempt: ISubmissionAttempt = {
    isAiTutorEnabled,
    isAiEvaluationUnderProgress,
    submissionCount: aiSubmissionCount,
    hasApprovedSubmission,
  };

  return (
    <Wrapper>
      {canSendToEvaluate(submissionAttempt) && (
        <AiSubmissionButton
          isAiEvaluationUnderProgress={isAiEvaluationUnderProgress}
          aiTutorConsent={aiTutorConsent}
          submitTaskForAiEvaluation={submitTaskForAiEvaluation}
          handleAiEvaluationWithoutConsent={handleAiEvaluationWithoutConsent}
        />
      )}

      {canSendToInstructor(submissionAttempt) && (
        <Button
          label='Submit to instructor'
          variant='primary'
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            submitTask();
          }}
          isLoading={isLoadingModal}
          disabled={isAiEvaluationUnderProgress}
        />
      )}

      <AiConsentModal
        modalIsOpen={consentModalIsOpen}
        handleClose={() => toggleConsentModal(false)}
        submitTaskForAiEvaluation={submitTaskForAiEvaluation}
      />
    </Wrapper>
  );
};

export default TaskSubmissionButton;
