import React from 'react';
import TheModal from '../../../shared/components/TheModal';
import { Body, Button, Header } from '@careerfoundry/bubble';
import { ButtonWrapper, ModalContent, ModalWrapper } from './styles';
import { setAiTutorConsent } from '../../actions';

type Props = {
  handleClose: () => void;
  modalIsOpen: boolean;
  submitTaskForAiEvaluation: () => void;
};

const AiConsentModal = ({ handleClose, modalIsOpen, submitTaskForAiEvaluation }: Props) => {
  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAiTutorConsent();
    submitTaskForAiEvaluation();
    handleClose();
  };

  return (
    <ModalWrapper>
      <TheModal handleClose={handleClose} isModalOpen={modalIsOpen}>
        <ModalContent>
          <Header tag='h4' variant='h4'>
            Data Protection
          </Header>
          <Body tag='p' variant='medium'>
            To proceed with the evaluation, you must consent to pass your submission on to a third-party software. For further
            details, please see our full
            <a href='https://images.careerfoundry.com/public/ai_evaluate_consent.pdf' className='ds-text--bold' target='_blank'>
              {' '}
              Evaluate Data Protection Policy
            </a>
            . You may withdraw your consent at any time by contacting your student advisor.
          </Body>
          <ButtonWrapper>
            <Button
              label='Cancel'
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.preventDefault();
                handleClose();
              }}
              size='standard'
              variant='secondary'
            />
            <Button
              label='Accept and confirm submission'
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleSubmit(event)}
              size='standard'
              variant='primary'
            />
          </ButtonWrapper>
        </ModalContent>
      </TheModal>
    </ModalWrapper>
  );
};

export default AiConsentModal;
