import React, { useEffect } from 'react';
import { Status } from './styles';
import { SetAiEvaliationStatusActionParams } from '../../container/AiTaskSubmissionStatus';
import { Collection } from 'lodash';

export interface AiTaskSubmissionStatusProps {
  aiEvaluationId: number | null;
  aiEvaluationStatus: string | null;
  isAiEvaluationUnderProgress: boolean;
  aiEvaluationError: boolean | null;
  setAiEvaluationStateAction: ({ inProgress, status, isError }: SetAiEvaliationStatusActionParams) => void;
}

interface AiTutorSubmissionsResponse {
  data: {
    state: string;
  };
}

const AiTaskSubmissionStatus = ({
  aiEvaluationId,
  aiEvaluationStatus,
  isAiEvaluationUnderProgress,
  aiEvaluationError,
  setAiEvaluationStateAction,
}: AiTaskSubmissionStatusProps) => {
  const isAiEvaluationUnderReview = aiEvaluationStatus === 'waiting_for_review';
  const isAiEvaluationFailed = aiEvaluationStatus === 'failed';
  const aiEvaluationSuccessfulStates = ['almost_there', 'not_yet', 'a_little_more', 'approved', 'submission_reviewed'];

  useEffect(() => {
    let intervalId: number;

    if (isAiEvaluationUnderProgress && isAiEvaluationUnderReview) {
      intervalId = window.setInterval(() => {
        const url = `/api/v1/ai_tutor_submissions/${aiEvaluationId}`;

        fetch(url)
          .then((response) => response.json())
          .then((data: AiTutorSubmissionsResponse) => {
            const aiEvaluationStatusViaResponse = data.data.state;

            if (aiEvaluationSuccessfulStates.includes(aiEvaluationStatusViaResponse)) {
              const origin = window.location.origin;
              const path = window.location.pathname;
              const url = `${origin}${path}#step_submission_history`;
              window.location.href = url;
              window.location.reload();
            } else if (isAiEvaluationFailed) {
              throw new Error();
            }
          })
          .catch(() => {
            clearInterval(intervalId);
            setAiEvaluationStateAction({
              inProgress: false,
              status: null,
              isError: true,
            });
          });
      }, 30000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isAiEvaluationUnderProgress, isAiEvaluationUnderReview]);

  return (
    <div>
      {!aiEvaluationError && isAiEvaluationUnderProgress && isAiEvaluationUnderReview && (
        <Status>
          <i className='fa-light fa-hourglass-start'></i>
          <span className='ds-text__body--small'>
            Evaluation is in process, it usually takes 10 minutes; we will notify you when it’s ready.
            <br />
            <small>(if more than 10 minutes have passed and you are blocked, please contact our student team)</small>
          </span>
        </Status>
      )}
      {!!aiEvaluationError && (
        <Status>
          <i className='fa-light fa-triangle-exclamation'></i>
          <span className='ds-text__body--small'>
            We were not able to evaluate your task <span className='ds-text--bold'>please try again</span>. Ensure you submit the
            correct files and follow the task directions closely.
          </span>
        </Status>
      )}
    </div>
  );
};

export default AiTaskSubmissionStatus;
