import React from 'react';
import TaskSubmissionButton from '../../components/TaskSubmissionButton';
import { connect } from 'react-redux';
import {
  submitTask,
  submitTaskForAiEvaluation,
  handleAiEvaluationWithoutConsent,
  toggleConsentModal,
  AiSubmissionAttempts,
} from '../../actions';
import { Dispatch } from 'redux';

interface TaskSubmissionButtonContainerProps {
  isLoadingModal: boolean;
  submitTask: () => void;
  submitTaskForAiEvaluation: () => void;
  isAiEvaluationUnderProgress: boolean;
  consentModalIsOpen: boolean;
  handleAiEvaluationWithoutConsent: () => void;
  AiSubmissionAttempts: () => void;
  toggleConsentModal: (modalIsOpen: boolean) => void;
  aiTutorInfo: {
    isConsentProvidedByUser: boolean;
    isEnabled: boolean;
    isEvaluationUnderReview: boolean;
  };
  aiSubmissionHistory: {
    attempts: any[];
    attemptsCount: number;
    hasApproved: boolean;
  };
}

interface State {
  taskSubmission: {
    isLoadingModal: boolean;
    consentModalIsOpen: boolean;
    aiEvaluationState: {
      inProgress: boolean;
    };
    aiSubmissionHistory: {
      attempts: any[];
      hasApproved: boolean;
      attemptsCount: number;
    };
  };
}

const TaskSubmissionButtonContainer = ({
  isLoadingModal,
  submitTask,
  submitTaskForAiEvaluation,
  aiTutorInfo,
  isAiEvaluationUnderProgress,
  consentModalIsOpen,
  handleAiEvaluationWithoutConsent,
  toggleConsentModal,
  AiSubmissionAttempts,
  aiSubmissionHistory,
}: TaskSubmissionButtonContainerProps) => {
  React.useEffect(() => {
    AiSubmissionAttempts();
  }, []);

  return (
    <TaskSubmissionButton
      isLoadingModal={isLoadingModal}
      submitTask={submitTask}
      submitTaskForAiEvaluation={submitTaskForAiEvaluation}
      isAiEvaluationUnderProgress={isAiEvaluationUnderProgress || aiTutorInfo.isEvaluationUnderReview}
      isAiTutorEnabled={aiTutorInfo.isEnabled}
      aiTutorConsent={aiTutorInfo.isConsentProvidedByUser}
      consentModalIsOpen={consentModalIsOpen}
      handleAiEvaluationWithoutConsent={handleAiEvaluationWithoutConsent}
      toggleConsentModal={toggleConsentModal}
      aiSubmissionCount={aiSubmissionHistory?.attemptsCount || 0}
      hasApprovedSubmission={aiSubmissionHistory?.hasApproved || false}
    />
  );
};

export const mapStateToProps = ({
  taskSubmission: {
    isLoadingModal,
    consentModalIsOpen,
    aiEvaluationState: { inProgress },
    aiSubmissionHistory,
  },
}: State) => ({
  isLoadingModal,
  consentModalIsOpen,
  isAiEvaluationUnderProgress: inProgress,
  aiSubmissionHistory,
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleAiEvaluationWithoutConsent: () => handleAiEvaluationWithoutConsent()(dispatch),
  submitTask: () => submitTask()(dispatch),
  submitTaskForAiEvaluation: () => submitTaskForAiEvaluation()(dispatch),
  toggleConsentModal: (modalIsOpen: boolean) => dispatch(toggleConsentModal(modalIsOpen)),
  AiSubmissionAttempts: () => AiSubmissionAttempts()(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskSubmissionButtonContainer);
